<template>
	<div class="test2"></div>
</template>

<script>
	import {
		registerUser
	} from "@/http/api";
	import LStorage from "@/storage/local";
	export default {
		components: {},
		data() {
			return {
				routeObj: {},
				siteInfo: {}, //来源对象(包括appid和site_code)
				attach: ''
			};
		},
		created() {
			this.routeObj = this.$route.query;
			this.siteInfo = LStorage.getItem('siteInfo')
			this.attach = LStorage.getItem('regAttach');

			if (this.siteInfo.appid && this.routeObj.code && this.attach) {
				this.register();
			} else {
				this.$toast.fail('登录失败 - 10001')
			}
		},
		methods: {
			//  执行注册
			async register() {
				const res = await registerUser({
					data: {
						site_code: this.siteInfo.site_code,
						appid: 'wx2072f5b290784490',
						code: this.routeObj.code,
						attach: this.attach
					}
				});
				if (res.code == 200) {
					//console.log(res)
					//  用户登录成功
					LStorage.setItem('userToken', res.data.token)
					LStorage.setItem('userInfo', res.data.userinfo)
					LStorage.removeItem('regAttach')

					this.$router.push(this.routeObj.state);
				} else {
					Toast.fail('注册失败 - 10001')
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
</style>
